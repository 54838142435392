import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BookingOfferSummary } from '../../components/booking-offer-summary/booking-offer-summary';
import { ColorButton } from '../../components/color-button/color-button';
import { Price, PriceType } from '../../components/price/price';
import { CruiseService } from '../../services/search';
import { BookingFormProps } from './BookingFormProps';

import { useMap } from '../../hooks/useMap';
import { useTracking } from '../../hooks/useTracking';
import { useSearchApi } from '../../contexts/search-api.context';

export const BookingServicesPage: React.FC<BookingFormProps> = (props: BookingFormProps) => {
  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // Translation
  const { t } = useTranslation('common');

  // Api Context
  const { searchApi } = useSearchApi();

  const { trackEvent } = useTracking();

  // States
  const [servicesGrouped, setServicesGrouped] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useMap<string, boolean>();

  // Methods
  const createServiceGroups = () => {
    setServicesGrouped(null);

    if (props.bookingState.bookingDetails) {
      const s = props.bookingState.bookingDetails.services;
      if (s && s.length > 0) {
        const c = s;

        c.sort((a, b) => {
          return a.type.localeCompare(b.type);
        });

        let cg = c.reduce((groups: any, item) => {
          const group = groups[item.type] || [];
          group.push(item);
          groups[item.type] = group;
          return groups;
        }, {});
        if (cg) {
          setServicesGrouped(cg);
        }
      }
    }
  };

  const handleSelection = (serviceName: string) => (event: any, checked: boolean) => {
    setSelectedServices.set(serviceName, checked);
  };

  const handleSubmit = (event: any) => {
    props.bookingState.services =
      props.bookingState.bookingDetails?.services?.filter(
        (e) => selectedServices.has(e.name) && selectedServices.get(e.name) === true
      ) || [];
    trackEvent('services_select', { services: props.bookingState.services.map((s) => s.type) });
    props.bookingState.completedSteps.push(2);
    props.updateBooking(props.bookingState);
    props.onSubmit();
  };

  const loadServices = () => {
    if (
      props.bookingState.departureDate &&
      props.bookingState.bookingDetails?.shipCode &&
      props.bookingState.cabinType
    ) {
      setIsLoading(true);

      const p = searchApi.getCruiseServices({
        cruiseId: props.bookingState.departureDate.id,
        suiteId: props.bookingState.cabinType?.cabinCode,
        guests: {
          adults: props.bookingState.guestsState.getGuestListByType('adult').length,
          children: props.bookingState.guestsState.getGuestListByType('child').length
        }
      });

      p.then((response) => {
        if (props.bookingState.bookingDetails) {
          props.bookingState.bookingDetails.services = response.data;

          props.updateBooking(props.bookingState);

          createServiceGroups();

          setSelectedServices.setAll(
            new Map(
              props.bookingState.bookingDetails.services.map((s) => [
                s.name,
                props.bookingState.services.filter((e) => e.name === s.name).length > 0
              ])
            )
          );
        }
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    loadServices();
  }, [props.bookingState.cabinType]);

  // Components
  const Title = () => {
    if (!isLayoutMedium) {
      return (
        <>
          <Typography variant="h2" textAlign="center" marginBottom="8px">
            {t('booking.services.sub-title')}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h3" textAlign="left" marginBottom="8px">
            {t('booking.services.sub-title')}
          </Typography>
        </>
      );
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <>
      <Stack direction={!isLayoutMedium ? 'column' : 'row'} spacing={3}>
        {isLayoutMedium && (
          <Box width="30%">
            <BookingOfferSummary
              bookingState={props.bookingState}
              updateBooking={props.updateBooking}
              readonly={true}
            />
          </Box>
        )}
        <Box width={!isLayoutMedium ? '100%' : '70%'}>
          <Stack spacing={3}>
            <Title />
            <Alert severity="info" variant="filled" style={{ marginTop: 0 }}>
              <AlertTitle>
                <Typography variant="h3" textAlign="left" color="#fff">
                  {t('booking.departure.whats-included-in-price.title')}
                </Typography>
              </AlertTitle>
              <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
                {t(
                  props.bookingState.bookingDetails?.cruiseLine === 'CELESTYAL' &&
                    props.bookingState.departureDate?.fromDate &&
                    props.bookingState.departureDate.fromDate >= new Date(2025, 3 - 1, 21)
                    ? 'booking.departure.whats-included-in-price.description.CELESTYAL_WIFI'
                    : `booking.departure.whats-included-in-price.description.${props.bookingState.bookingDetails?.cruiseLine}`
                )}
              </Typography>
            </Alert>
            {!isLoading && (
              <Box className="accordion-container">
                {props.bookingState.bookingDetails &&
                  servicesGrouped &&
                  Object.keys(servicesGrouped).map((serviceGroupName: string, i: number) => (
                    <Accordion key={i} defaultExpanded={true}>
                      <AccordionSummary
                        color="secondary"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${serviceGroupName}-content`}
                        id={`panel-${serviceGroupName}-header`}
                      >
                        <Typography sx={{ width: '80%', flexShrink: 0 }}>
                          {t(`search.filters.services.options.${serviceGroupName}`)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={3}>
                          {(servicesGrouped[serviceGroupName as any] as Array<CruiseService>).map((c, i) => (
                            <Grid
                              container
                              key={i}
                              justifyContent={isMobile ? 'flex-start' : undefined}
                              alignItems={'center'}
                            >
                              <Grid item>
                                <Checkbox checked={selectedServices.get(c.name)} onChange={handleSelection(c.name)} />
                              </Grid>
                              <Grid item xs={!isMobile} style={{ fontWeight: c.descriptionKey ? 'bold' : 'normal' }}>
                                {c.name}
                              </Grid>
                              {isMobile && c.descriptionKey && (
                                <Grid item margin={'0 42px'}>
                                  {t(`search.filters.services.descriptions.${c.descriptionKey}`, '')}
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={12}
                                sm={isMobile ? undefined : 'auto'}
                                justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                                style={{ marginTop: isMobile ? 16 : 0 }}
                              >
                                <Price
                                  mainPrice={
                                    new PriceType(
                                      c.price,
                                      __CONFIG__.currency.default,
                                      c.perPerson ? null : -1,
                                      'estimate'
                                    )
                                  }
                                  localPrice={
                                    new PriceType(
                                      c.priceLocalCcy,
                                      __CONFIG__.currency.localCurrency,
                                      c.perPerson ? null : -1,
                                      'estimate'
                                    )
                                  }
                                />
                              </Grid>
                              {!isMobile && c.descriptionKey && (
                                <Grid item xs={12} margin={'0 42px'}>
                                  {t(`search.filters.services.descriptions.${c.descriptionKey}`, '')}
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {!servicesGrouped && <Box>{t('booking.services.no-services-available')}</Box>}
              </Box>
            )}
            {isLoading && (
              <Stack direction="column" spacing={1}>
                <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
                <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
              </Stack>
            )}
            <ColorButton label={t('booking.services.choose-button-label')} onClick={handleSubmit} />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
